const areWeInMobileSafari = () => {
  const userAgent = navigator.userAgent || navigator.vendor || ''
  return userAgent.includes('iPhone') && userAgent.includes('Safari')
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    safari: any
  }
}

const areWeInSafari = () => {
  return window.safari !== undefined
}

const areWeInFirefox = () => {
  return navigator?.userAgent?.includes('Firefox')
}

const areWeInChrome = () => {
  return navigator?.userAgent?.includes('Chrome')
}

export const inSafari = areWeInSafari()
export const inMobileSafari = areWeInMobileSafari()
export const inFirefox = areWeInFirefox()
export const inChrome = areWeInChrome()
